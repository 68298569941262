<template>
  <div
    ref="modal"
    class="v-combobox"
    :class="{ 'v-combobox__disabled': isDisabled }"
  >
    <label v-if="label" class="v-combobox-label">
      {{ label }}
    </label>
    <div class="v-combobox-input__control">
      <img
        v-if="currentImage"
        class="v-combobox-input__currency"
        :src="currentImage"
        :alt="inputValue"
      />
      <input
        v-if="savedRequisites"
        v-model="lazyValue"
        autocomplete="cc-number"
        class="v-combobox-input"
        :class="{ 'v-combobox-input-image-none': imageName === 'none' }"
        :placeholder="modelValue || currentName"
        @click="onClick"
        @blur="onBlur"
        @input="onInput"
      />

      <input
        v-else
        v-model="inputValue"
        type="text"
        class="v-combobox-input"
        :class="{ 'v-combobox-input-image-none': imageName === 'none' }"
        :placeholder="currentName"
        @blur="onBlur"
        @click="isDropdownVisible = !isDropdownVisible"
      />

      <div v-if="optionSelected && clearable" class="v-combobox-input__clear">
        <SvgoIconClose @click="clear" />
      </div>
      <div
        v-else
        class="v-combobox-input__icon"
        :class="{
          'v-combobox-input__icon--active': isDropdownVisible,
          'v-combobox-dropdown__hide': options.length === 0,
        }"
      >
        <SvgoIconArrow @click="isDropdownVisible = !isDropdownVisible" />
      </div>
    </div>
    <transition name="expand">
      <div v-show="errorString" class="v-combobox-input__base-hint">
        <SvgoIconTooltip />
        <small
          class="inline-block"
          :class="[errorString ? 'v-combobox-input__base-hint__error' : '']"
        >
          {{ errorString }}
        </small>
      </div>
    </transition>
    <transition name="select-menu">
      <div
        v-if="isDropdownVisible"
        class="v-combobox-dropdown"
        :class="{ 'v-combobox-dropdown__hide': options.length === 0 }"
      >
        <CommonDirectionFilter
          v-if="!pending && filtersDirection"
          v-model="activeFilter"
          :items="filterItems"
          class="v-combobox-filter"
        />
        <template v-if="savedRequisites">
          <div class="v-combobox-dropdown-scroll">
            <div
              v-for="item in filtersOptions"
              :key="item[valueName]"
              class="v-combobox-dropdown__item"
              :class="{
                'v-combobox-dropdown__item--active': checkOptionActive(item),
              }"
              @mousedown="onSelected(item)"
            >
              <img
                v-if="imageName !== 'none'"
                class="v-combobox-dropdown__image"
                :src="currentImage"
                :alt="item[labelName]"
              />
              {{ item[labelName] }}
            </div>
          </div>
        </template>

        <template v-else>
          <template v-if="searchDirections.length > 0">
            <div class="v-combobox-dropdown-scroll">
              <div
                v-for="item in searchDirections"
                :key="item[valueName]"
                class="v-combobox-dropdown__item"
                :class="{
                  'v-combobox-dropdown__item--active': checkOptionActive(item),
                }"
                @mousedown="onSelected(item)"
              >
                <img
                  v-if="imageName !== 'none'"
                  class="v-combobox-dropdown__image"
                  :src="getImage(item)"
                  :alt="item[labelName]"
                />
                {{ item[labelName] }}
              </div>
            </div>
          </template>
          <template v-else>Нет результатов</template>
        </template>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { useFuse } from "@vueuse/integrations/useFuse";
import { useCalculatorApi } from "~/api/useCalculatorApi";
import { validateLuhnNumber } from "~/utils/card";
import { addMask, getNextCursorPosition, setSelection } from "~/utils/mask";

const props = defineProps({
  options: {
    type: Array,
    default: () => [],
  },
  labelName: {
    type: String,
    default: "label",
  },
  valueName: {
    type: String,
    default: "value",
  },
  currentName: {
    type: String,
    default: "",
  },
  currentImage: {
    type: String,
    default: "",
  },
  imageName: {
    type: String,
    default: "none",
  },
  savedRequisites: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: "",
  },
  filtersDirection: {
    type: Boolean,
    default: false,
  },
  error: {
    type: String,
    default: "",
  },
  modelValue: {
    type: String,
    default: "",
  },
  checkLuhn: {
    type: Boolean,
    default: false,
  },
  mask: {
    type: String,
    default: "",
  },
  clearable: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits<{
  (e: "update:modelValue", value: string): void;
  (e: "change", value: string | number): void;
  (e: "clear"): void;
}>();

const isDropdownVisible = ref(false);
const inputValue = ref("");
const optionSelected = ref(null);

const activeFilter = ref("all");
const { data: filters, pending } = await useCalculatorApi().fetchSetting();

const filterItems = computed(() => {
  if (!filters.value)
    return [
      {
        name: "Все",
        code: "all",
      },
    ];
  return filters.value.filters.main.filter((item) => item.code !== "u");
});

const filteredDirections = computed(() => {
  if (!props.options) return [];
  if (activeFilter.value === "all") return props.options;
  return props.options.filter((item) => {
    const filter = item.filter[0];
    return filter === activeFilter.value;
  });
});

const onBlur = () => {
  inputValue.value = "";
};

const onClick = () => {
  if (props.savedRequisites) {
    isDropdownVisible.value = !isDropdownVisible.value;
  }
};

const filtersOptions = computed(() => {
  if (props.filtersDirection) {
    return filteredDirections.value;
  } else {
    return props.options;
  }
});

const { results } = useFuse(inputValue, filtersOptions, {
  fuseOptions: { keys: ["keywords", "name", "label"] },
  matchAllWhenSearchEmpty: true,
});

const searchDirections = computed(() => {
  return results.value.map((item) => item.item);
});

const getImage = (item: any) => {
  return item.logo ? item.logo[props.imageName] : item[props.imageName];
};

const onSelected = (item: any) => {
  if (props.savedRequisites) {
    emit("change", item.name);
  } else {
    emit("change", item.id);
  }
  optionSelected.value = item;
  inputValue.value = "";
  isDropdownVisible.value = false;
};

const checkOptionActive = (item: any) => {
  if (optionSelected.value) {
    return item[props.valueName] === optionSelected.value[props.valueName];
  }
};

const isDisabled = computed(() => {
  if (!props.savedRequisites) {
    return props.options.length === 0;
  }
});

const errorString = ref("");

const modal = ref(null);

onClickOutside(modal, () => {
  isDropdownVisible.value = false;
});

const currentMask = computed(() => {
  switch (props.mask) {
    case "card":
      return "#### #### #### #### ####";
    default:
      return null;
  }
});

const lazyValue = ref(
  addMask(props.modelValue, currentMask.value) || props.modelValue,
);

watch(
  () => props.modelValue,
  (value) => {
    lazyValue.value = addMask(value, currentMask.value) || value;
  },
);

watch(
  () => props.error,
  (value) => {
    errorString.value = value || "";
  },
);

function onInput(e: InputEvent) {
  optionSelected.value = null;
  if (props.mask) {
    let endPos = (e.target as HTMLInputElement).selectionEnd || 0;
    const oldValue = lazyValue.value;
    const newValue = addMask(lazyValue.value, currentMask.value);
    endPos = getNextCursorPosition(endPos, oldValue, newValue, " ");
    if (newValue !== null) {
      lazyValue.value = newValue;
    }
    nextTick(() => {
      setSelection(e.target, endPos);
    });
  }
  if (props.checkLuhn) {
    if (!validateLuhnNumber(lazyValue.value)) {
      errorString.value = "Номер карты некорректен";
    } else {
      errorString.value = "";
    }
  }
  emit("update:modelValue", lazyValue.value.replace(/\s/g, ""));
}

function clear() {
  optionSelected.value = null;
  emit("clear");
}
</script>

<style lang="scss">
.v-combobox {
  position: relative;

  &-label {
    display: inline-block;
    margin-bottom: 0.8rem;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: normal;
    color: $body-color;
  }

  &-filter {
    margin-bottom: 2rem;
  }

  &-input {
    flex: 1;
    width: 100%;
    height: 4.6rem;
    border: none;
    outline: none;
    background: $input-color;
    padding: 0 1.7rem 0 4.8rem;
    color: $body-color;
    font-size: 1.5rem;

    &-image-none {
      padding: 0 1.7rem;
    }

    &::placeholder {
      color: $body-color;
    }

    &__control {
      position: relative;
      display: flex;
      align-items: center;
      border: 2px solid $body-color;

      &:focus-within {
        border: 2px solid $brand-green;
      }
    }

    &__currency {
      position: absolute;
      left: 1.7rem;
      width: 2.6rem;
      height: auto;
    }

    &__clear {
      cursor: pointer;
      width: 2.2rem;
      height: 2.2rem;
      color: inherit;
      position: absolute;
      right: 1.7rem;

      &:hover {
        color: $brand-green;
      }
    }

    &__icon {
      position: absolute;
      right: 1.7rem;
      display: flex;
      z-index: 0;
      overflow: hidden;
      width: 1.4rem;
      height: 1.4rem;
      transform: rotate(180deg);
      transition: transform $default-transition;

      &--active {
        transform: rotate(0deg);
        transition: transform $default-transition;
      }

      svg {
        display: block;
      }
    }

    &__base-hint {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: normal;
      margin-top: 0.5rem;
      color: $body-color;

      svg {
        width: 1.5rem;
        height: 1.5rem;
        flex-shrink: 0;
        color: $brand-red;
      }

      &__error {
        color: $brand-red;
      }
    }
  }

  &-dropdown {
    position: absolute;
    top: calc(100% + 5px);
    z-index: 15;
    width: 100%;
    padding: 1.5rem 1.7rem;
    text-align: left;
    border: 2px solid $body-color;
    background-color: $bg-color;

    &__hide {
      display: none;
    }

    &-scroll {
      max-height: 24rem;
      overflow: auto;
      scrollbar-color: $brand-green;

      &::-webkit-scrollbar {
        width: 4px;
        cursor: pointer;
      }

      &::-webkit-scrollbar-track {
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background: $brand-green;
        border-radius: 5.5rem;
      }
    }

    &__item {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.6rem;
      cursor: pointer;
      font-size: 1.5rem;

      &:hover {
        background-color: rgba($base-500, 0.1);
      }

      &--active {
        background-color: rgba($base-500, 0.1);
      }
    }

    &__image {
      width: 2.6rem;
      height: auto;
    }
  }

  &__disabled {
    pointer-events: none;

    & .v-combobox-input__control {
      border: 2px solid #929292;
    }

    & .v-combobox-input__icon {
      svg {
        color: #929292;
      }
    }
  }
}
</style>
